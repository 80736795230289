/**
 * This module provides login functionality through AWS Cognito.
 * @author Zach Oliver
 */
import React, { useState } from 'react'
import { Button } from '@progress/kendo-react-buttons'
import { Input } from '@progress/kendo-react-inputs'
import './login.css'
import KiteLogo from './kite_logo.svg'
import KiteBackground from './Kite_Login_BG.png'

/**
 * Serves ATS Login form
 *
 * @param {image} props.logo - Header image. If no logo is specified, the component
 * will use a generic Kite logo.
 *
 * @param {image} props.background - Background image. If no background is specified, the component
 * will use a generic Kite background.
 *
 * @param {String} props.headerText - Header text. H1 text that will be placed under the logo.
 * If no text is specified, none will be rendered.
 *
 * @param {String} props.errorMessage- Error message text. If this is set, it will render
 * in red text above the inputs.
 *
 * @param {function} props.onsubmit - Function that will be called when the user submits the
 * login form. Will pass the user Id and password.
 *
 * @param {String} props.loginButtonText - Text that will be displayed on the form submission button. Defaults to "Login".
 *
 * @param {JSX} props.resetLink - JSX for the reset link. If not specified, nothing is rendered.
 *
 * @param {String} props.userIdText - The placeholder text to be displayed in the user id input field. Defaults to "Email".
 *
 * @param {String} props.passwordText - The placeholder text to be displayed in the password input field. Defaults to "Password".
 *
 * @return  ATS Login form
 */
export default function LogIn({
  loginButtonText = 'Login',
  userIdText = 'Email',
  passwordText = 'Password',
  logo = KiteLogo,
  background = KiteBackground,
  ...props
}) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  /**
   * return a boolean value for Login Button to show active or inactive
   * @return  {Boolean}            True or False
   */
  function validateForm() {
    let inputTrue = false
    if (email.length > 0 && password.length > 0) {
      inputTrue = true
      document.getElementById('submitButton').style = 'color:blue'
    }

    return inputTrue
  }

  /**
   * Calls the registered submit callback in the properties, if it exists
   *
   * @param   {string} email  email of the User
   * @param   {string} password  password of the User
   */
  async function handleSubmit(event) {
    event.preventDefault()

    if (props.onSubmit) {
      props.onSubmit(event, email, password)
    }
  }
  const backgroundStyle =
  {
    backgroundImage: `url( ${background})`,
    backgroundSize: 'cover',
    minHeight: '380px',
    position: 'relative',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    width: '100%'
  }
  return (
    <div style={backgroundStyle}>
      <div className='Login'>
        <img
          src={logo}
          alt='Login page logo'
          style={{ width: '100%', height: 'auto' }}
          id='logoImage'
        />
        <h1>{props.headerText}</h1>
        {props.errorMessage ? (
          <span className='errorMessage'>{props.errorMessage}</span>
        ) : null}
        <form id='loginForm' className='signin-form' onSubmit={handleSubmit}>
          <div className='input-field'>
            <Input
              placeholder={userIdText}
              autoFocus
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              placeholder={passwordText}
              value={password}
              type='password'
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <Button
            className='buttn'
            id='submitButton'
            icon='lock'
            bssize='large'
            disabled={!validateForm()}
            type='submit'
          >
            {loginButtonText}
          </Button>
          {props.resetLink ? props.resetLink : null}
        </form>
      </div>
    </div>
  )
}
