/**
 * This module provides new password functionality through AWS Cognito.
 * @author Craig Shatswell
 *
 */
 import React, { useState } from 'react'
 import { Button } from '@progress/kendo-react-buttons'
 import { Input } from '@progress/kendo-react-inputs'
 import './login.css'
 import KiteLogo from './kite_logo.svg'
 import KiteBackground from './Kite_Login_BG.png'
 
 /**
  * Serves ATS Reset Password form
  *
  * @param {image} props.logo - Header image. If no logo is specified, the component
  * will use a generic Kite logo
  *
  * @param {image} props.background - Background image. If no background is specified, the component
  * will use a generic Kite background.
  *
  * @param {String} props.headerText - Header text. H1 text that will be placed under the logo.
  * If no text is specified, none will be rendered.
  *
  * @param {String} props.errorMessage- Error message text. If this is set, it will render
  * in red text above the inputs.
  *
  * @param {function} props.onSubmit - Function that will be called when the user submits the
  * new password form. Will pass the email.
  *
  * @param {String} props.submitButtonText - Text that will be displayed on the form submission button. Defaults to "Save New Password"
  *
  * @param {String} props.newPasswordText - The placeholder text to be displayed in the new password input field. Defaults to "New Password"
  *
  * @param {String} props.newPasswordConfirmText - The placeholder text to be displayed in the new password input field. Defaults to "Confirm New Password"
  *
  * @param {String} props.instructions = The instructions to be displayed to the user.  Defaults to "Please enter your new password below."
  *
  * @return  ATS New Password form
  */
 export default function NewPassword({
   submitButtonText = 'Save New Password',
   newPasswordText = 'New Password',
   newPasswordConfirmText = 'Confirm New Password',
   instructions = 'Please enter your new password below.',
   logo = KiteLogo,
   background = KiteBackground,
   ...props
 }) {
   const [newPassword, setNewPassword] = useState('')
   const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
 
   /**
    * return a boolean value for New Password Button to show active or inactive
    * @return  {Boolean}            True or False
    */
   function validateForm() {
     let inputTrue = false
     if (newPassword.length > 0 && newPasswordConfirm.length > 0 && newPassword === newPasswordConfirm) {
       inputTrue = true
       document.getElementById('submitButton').style = 'color:blue'
     }
 
     return inputTrue
   }
 
   /**
    * Calls the registered submit callback in the properties, if it exists
    *
    * @param {String} newPassword
    */
   async function handleSubmit(event) {
     event.preventDefault()
 
     if (props.onSubmit) {
       props.onSubmit(event, newPassword)
     }
   }
   const backgroundStyle =
   {
     backgroundImage: `url( ${background})`,
     backgroundSize: 'cover',
     minHeight: '380px',
     position: 'relative',
     backgroundPosition: 'center',
     backgroundRepeat: 'no-repeat',
     height: '100vh',
     width: '100%'
   }
   return (
     <div style={backgroundStyle}>
       <div className='Login'>
         <img
           src={logo}
           alt='New Password page logo'
           style={{ width: '100%', height: 'auto' }}
           id='logoImage'
         />
         <h1>{props.headerText}</h1>
         {props.errorMessage ? (
           <span className='errorMessage'>{props.errorMessage}</span>
         ) : null}
         <br />
         <span className=''>{instructions}</span>
         <form id='newPasswordForm' className='signin-form' onSubmit={handleSubmit}>
           <div className='input-field'>
             <Input
               placeholder={newPasswordText}
               autoFocus
               type='password'
               value={newPassword}
               onChange={(e) => setNewPassword(e.target.value)}
             />
           </div>
           <div className='input-field'>
             <Input
               placeholder={newPasswordConfirmText}
               autoFocus
               type='password'
               value={newPasswordConfirm}
               onChange={(e) => setNewPasswordConfirm(e.target.value)}
             />
           </div>
           <Button
             className='buttn'
             id='submitButton'
             icon='lock'
             bssize='large'
             disabled={!validateForm()}
             type='submit'
           >
             {submitButtonText}
           </Button>
         </form>
       </div>
     </div>
   )
 }
 